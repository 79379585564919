.weather-map-container {
  display: flex;
  height: 100vh;
}

.weather-map-sidebar {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 250px;
  max-width: 400px;
}

.weather-map-sidebar .map-key {
  margin-top: 20px;
  padding: 10px;
  background-color: #333533;
  width: 100%;
}

.weather-map-content {
  flex: 1;
  height: 100%;
  border: 1px solid black;
}

.weather-map-content #map {
  height: 100%;
  width: 100%;
}

@media (max-width: 600px) {
  .weather-map-container {
    flex-direction: column;
  }

  .weather-map-sidebar {
    width: 100%;
    padding: 10px;
    position: relative;
  }

  .weather-map-content {
    height: 50vh;
    width: 100%;
  }
}
